import React from 'react'

const Contact = () => {
  return (
    <div className='contact' id='contact'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3>Contact</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ul className='list-unstyled'>
              <li>PJ Smith</li>
              <li>30 Jelliff Lane</li>
              <li>Southport, CT 06890</li>
              <li>
                <a href='mailto:psmith@mmountaincap.com'>psmith@mmountaincap.com</a>
              </li>
              <li>917-318-9701</li>
            </ul>

            <ul className='list-unstyled'>
              <li>Ridge Cromwell</li>
              <li>
                <a href='mailto:rcromwell@mmountaincap.com'>rcromwell@mmountaincap.com</a>
              </li>
            </ul>

            <ul className='list-unstyled'>
              <li>Joseph Sokol</li>
              <li>
                <a href='mailto:jsokol@mmountaincap.com'>jsokol@mmountaincap.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
